// LuckyLuke

export default function Welcome() {
	return (
		<>
			<article className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:flex-row bg-sky-50 group ">
				<section className="flex flex-col items-center justify-center  p-8">
					<div>
						<img
							src="images/jules2.webp"
							className="h-48 w-auto object-contain object-center rounded-full"
							alt="Julian Toutenburg - 
Bachelor Immobilienwirtschaft"
						/>
					</div>
					<h4 className="text-lg lg:text-xl font-sans text-light text-gray-700 opacity-70 italic text-center m-8 text-pretty">
						"Manchmal sind es kleine Veränderungen, die Großes
						bewirken. Mehr aus Ihrer <strong>Immobilie </strong>
						herauszuholen - das ist meine Leidenschaft."
					</h4>
					<p>
						<span className="font-extralight italic lg:text-3xl text-2xl text-sky-950 tracking-wider text-center">
							Julian Toutenburg
						</span>
					</p>
					<p className="font-light text-xs lg:text-sm text-sky-950  text-center">
						Bachelor Immobilienwirtschaft
					</p>
					<p className="font-light text-xs lg:text-sm text-sky-950  text-center">
						- Geschäftsführer -
					</p>
				</section>
				<section className="flex p-4">
					<div className=" lg:min-h-96 p-4 group min-w-full">
						<svg className="absolute w-0 h-0">
							<clipPath
								id="my-clip-path"
								clipPathUnits="objectBoundingBox"
							>
								<path d="M0.273,0.272 L0.273,0.505,0.266,0.508 C0.228,0.525,0.173,0.564,0.14,0.596 C0.115,0.621,0.089,0.652,0.076,0.672 C0.075,0.675,0.072,0.676,0.071,0.675 C0.07,0.674,0.07,0.675,0.071,0.676 C0.071,0.678,0.067,0.687,0.061,0.697 C0.042,0.73,0.026,0.77,0.017,0.808 C0.011,0.829,0.011,0.835,0.015,0.827 C0.017,0.824,0.019,0.822,0.02,0.822 C0.021,0.822,0.022,0.821,0.023,0.82 C0.023,0.817,0.041,0.795,0.046,0.791 C0.048,0.789,0.05,0.787,0.052,0.785 C0.054,0.781,0.072,0.763,0.078,0.758 C0.08,0.757,0.084,0.753,0.087,0.75 C0.094,0.744,0.144,0.706,0.15,0.702 C0.153,0.701,0.157,0.698,0.16,0.696 C0.182,0.682,0.219,0.663,0.235,0.656 C0.239,0.655,0.244,0.653,0.247,0.651 C0.251,0.65,0.257,0.648,0.261,0.646 C0.264,0.645,0.269,0.643,0.271,0.642 C0.273,0.64,0.274,0.651,0.274,0.84 L0.274,1,0.354,1 L0.434,1,0.434,0.821 L0.434,0.603,0.49,0.602 C0.521,0.601,0.556,0.601,0.569,0.602 L0.592,0.603,0.592,0.821 L0.592,1,0.671,1 L0.751,1,0.751,0.84 C0.751,0.73,0.751,0.641,0.752,0.641 C0.752,0.641,0.756,0.642,0.76,0.644 C0.764,0.645,0.77,0.648,0.774,0.649 C0.777,0.651,0.782,0.653,0.785,0.654 C0.787,0.655,0.796,0.659,0.804,0.663 C0.813,0.667,0.827,0.674,0.835,0.679 C0.844,0.683,0.851,0.687,0.852,0.687 C0.853,0.687,0.858,0.69,0.863,0.694 C0.868,0.698,0.874,0.702,0.876,0.703 C0.886,0.709,0.932,0.744,0.936,0.749 C0.939,0.752,0.942,0.755,0.943,0.755 C0.947,0.755,0.998,0.81,1,0.822 C1,0.827,1,0.829,1,0.826 C1,0.825,1,0.817,1,0.808 C0.976,0.681,0.888,0.573,0.764,0.51 L0.751,0.503,0.751,0.271 L0.751,0.039,0.671,0.039 L0.592,0.039,0.592,0.248 L0.592,0.457,0.587,0.456 C0.581,0.453,0.463,0.453,0.446,0.455 L0.433,0.457,0.434,0.248 L0.434,0.039,0.354,0.039 L0.274,0.039,0.273,0.272"></path>
							</clipPath>
						</svg>

						<div
							className="relative aspect-video min-h-48  group-hover:bg-center w-full h-full transform transition-all duration-500 ease-in-out "
							style={{
								backgroundImage:
									'url(./images/modernbuilding.jpeg)',
								clipPath: 'url(#my-clip-path)'
							}}
						></div>
					</div>
				</section>
			</article>
		</>
	);
}
